import { pagesPath } from '~/utils/$path';
import { NextPageWithLayout } from '~/types/layout';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { UrlObject } from 'url';
import { normalizePathTrailingSlash } from 'next/dist/client/normalize-trailing-slash';
import { denormalizePagePath } from 'next/dist/shared/lib/page-path/denormalize-page-path';
import {
  isDynamicRoute,
} from 'next/dist/shared/lib/router/utils';
import {getRouteRegex} from "next/dist/shared/lib/router/utils/route-regex";
import {addPathPrefix} from "next/dist/shared/lib/router/utils/add-path-prefix";

const basePath = (process.env.__NEXT_ROUTER_BASEPATH as string) || ''

 function addBasePath(path: string): string {
  // we only add the basepath on relative urls
  return addPathPrefix(path, basePath)
}

 function delBasePath(path: string): string {
  path = path.slice(basePath.length)
  if (!path.startsWith('/')) path = `/${path}`
  return path
}



function resolveDynamicRoute(
  parsedHref: {pathname :string},
  pages: string[],
  applyBasePath = true,
) {
  const { pathname } = parsedHref;
  const cleanPathname = normalizePathTrailingSlash(
    denormalizePagePath(applyBasePath ? delBasePath(pathname) : pathname),
  );

  if (cleanPathname === '/404' || cleanPathname === '/_error') {
    return parsedHref;
  }

  // handle resolving href for dynamic routes
  if (!pages.includes(cleanPathname!)) {
    // eslint-disable-next-line array-callback-return
    pages.some((page) => {
      if (isDynamicRoute(page) && getRouteRegex(page).re.test(cleanPathname!)) {
        parsedHref.pathname = applyBasePath ? addBasePath(page) : page;
        return true;
      }
    });
  }
  parsedHref.pathname = normalizePathTrailingSlash(parsedHref.pathname);
  return parsedHref;
}

const parsePath = (pathObj: any) => {
  let results: string[] = [];
  if (typeof pathObj.$url === 'function') {
    const path = pathObj.$url() as { pathname: string };
    results.push(path.pathname);
    if(!path.pathname.endsWith("/")){
      results.push(path.pathname + "/");
    }

  }

  for (let key of Object.keys(pathObj)) {
    if (typeof pathObj[key] === 'function' && key !== '$url') {
      results.push(...parsePath(pathObj[key]("")));
    } else {
      results.push(...parsePath(pathObj[key]));
    }
  }
  return results;
};

const getPageList = () => {
  return parsePath(pagesPath);
};

const Index: NextPageWithLayout = () => {
  const router = useRouter();

  const pages = getPageList();

  const test = (url:string) => {
    const resolvedRoute = resolveDynamicRoute(
      {pathname: url},
      pages,
    );
    console.log("resolvedRoute",url,resolvedRoute)

  }

  useEffect(() => {
    (async () => {
      console.log(pages);
      const resolvedRoute = resolveDynamicRoute(
        { pathname: router.asPath },
        pages,
      );

      console.log("resolvedRoute",resolvedRoute)
      if (resolvedRoute.pathname && pages.includes(normalizePathTrailingSlash(resolvedRoute.pathname)) && resolvedRoute.pathname !== '/') {
        console.log(`goto ${router.asPath}`)
        await router.replace(resolvedRoute.pathname, router.asPath);
      } else {
        console.log(`goto login page`)
        await router.push(pagesPath.$7ac6dd9d_43f8_4f2b_b632_9d18c6fe239f.login.$url());
      }
    })();
  }, []);

  return <></>;
};

export default Index;
